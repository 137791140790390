import React, { useState } from 'react'
import placeholder from '../assets/videos/placeholder.mp4'
import ReactPlayer from 'react-player'
import play from '../assets/images/paintsplat/playSplat.svg'
import vidBg from '../assets/images/vidBg.png'
import * as BsIcons from 'react-icons/bs'
import VideoPlayer from '../components/VideoPlayer/VideoPlayer'

function Course() {



  return (

    <div className='bg-gradient-to-t from-gray-600 to-black text-white min-h-screen pt-32'>

    <div className='bg-gradient-to-r from-punkpink to-punkpurple p-2'>
<p>Userbar</p>
    </div>
<div className='flex'>
<div className='w-4/6'>

<VideoPlayer />



<h2 className='text-4xl px-4 py-2'>Lesson title!</h2>
<p className='px-4'>In this lesson you will blah blah blah!</p>
    </div> 



<div className='w-2/6 bg-gradient-to-t from-black to-gray-600 h-screen text-white p-4'>
    <h3 className='text-4xl'>Module Content</h3>

</div>

    </div>
</div>
  )
}

export default Course