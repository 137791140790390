import React from 'react'

function Modules() {
  return (
    <div className='bg-gradient-to-t from-gray-600 to-black text-white min-h-screen pt-32 px-8 tablet:px-32'>
        <h3 className='text-center text-4xl'>MODULES</h3>

<div className='tablet:grid grid-cols-2 pb-16'>


<div className='bg-gradient-to-b from-punkpurple to-punkpink text-white m-8 p-1 rounded-xl text-center'>
    <div className='bg-black/90 rounded-xl p-4 min-h-full'>

<div className='flex justify-center'>
<h3 className='w-fit pb-2 text-4xl text-center text-transparent bg-gradient-to-l from-punkpink to-punkpurple bg-clip-text'>Web</h3>
</div>
<p> - Intro</p>
<p> - HTML Basics</p>
<p> - CSS Basics</p>
<p> - JS Basics</p>
<p> - VSCode</p>
<p> - React setup</p>
<p> - Functional structure</p>
<p> - Build something</p>
<p> - Particles</p>
<p> - Animation</p>
    </div>
</div>


<div className='bg-gradient-to-b from-punkpurple to-punkpink text-white m-8 p-1 rounded-xl text-center'>
    <div className='bg-black/90 rounded-xl p-4 min-h-full'>
    <div className='flex justify-center'>
<h3 className='w-fit pb-2 text-4xl text-center text-transparent bg-gradient-to-l from-punkpink to-punkpurple bg-clip-text'>App</h3>
</div>
<p> - Intro</p>
<p> - iOS & Android</p>
<p> - React Native Environment setup</p>
<p> - VSCode</p>
<p> - Build something</p>
</div>
</div>

<div className='bg-gradient-to-b from-punkpurple to-punkpink text-white m-8 p-1 rounded-xl text-center'>
    <div className='bg-black/90 rounded-xl p-4 min-h-full'>
    <div className='flex justify-center'>
<h3 className='w-fit pb-2 text-4xl text-center text-transparent bg-gradient-to-l from-punkpink to-punkpurple bg-clip-text'>Sales</h3>
</div>
<p> - Intro</p>
<p> - Process</p>
<p> - Lead Generation</p>
<p> - Rapport</p>
<p> - Need/ Find</p>
<p> - Trial Close</p>
<p> - Pitch</p>
<p> - Trial Close 2</p>
<p> - Pricing</p>
<p> - Negotiation</p>
<p> - Reciprocity</p>
<p> - Close</p>

</div>
</div>

<div className='bg-gradient-to-b from-punkpurple to-punkpink text-white m-8 p-1 rounded-xl text-center'>
    <div className='bg-black/90 rounded-xl p-4 min-h-full'>

    <div className='flex justify-center'>
<h3 className='w-fit pb-2 text-4xl text-center text-transparent bg-gradient-to-l from-punkpink to-punkpurple bg-clip-text'>Design/ Branding</h3>
</div>
<p> - Intro</p>
<p> - Font's</p>
<p> - Logo's</p>
<p> - Illustrator</p>
</div>

</div>
</div>

    </div>

  )
}

export default Modules