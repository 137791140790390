import React, { useEffect, useState } from 'react'
import './App.css'
import Waves from './components/Waves'
import paint3 from './assets/images/paintsplat/purpleWaves.svg'
import paint1 from './assets/images/paintsplat/pinkDrip.svg'
import paint2 from './assets/images/paintsplat/dotPink2.svg'
import paint4 from './assets/images/paintsplat/dotPurple.svg'
import { Route, Routes } from 'react-router-dom'
import Home from './screens/Home'
import NavBar from './components/NavBar/NavBar'
import About from './screens/About'
import Modules from './screens/Modules'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/Footer'
import Course from './screens/Course'
import Pricing from './screens/Pricing'
import Community from './screens/Community'
import Support from './screens/Support'
import svlLogo from './assets/images/svlLogo.svg'


function App() {
  const [loading, setLoading] = useState(true)
  const [holding, setHolding] = useState(true)
  const [passwordShow, setPasswordShow] = useState(false)
  const [password, setPassword] = useState('')

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false)
      }, 500)
    }
  })


  const passwordCheck = () => {
    if (password === 'smoothbrain') {
      setHolding(false)
    } else {
      alert('fuck off!')
    }
  }

  return (
holding ? 


<>  
    <div className='flex absolute w-screen justify-center h-screen z-50 bg-black overflow-hidden'>
        <div className='absolute bottom-0 w-screen bg-punkpurple z-50'>
          <p className='text-gray-200 text-center text-sm hover:text-white hover:scale-125 duration-300' onClick={() => setPasswordShow(!passwordShow)}>Special button just for Sam</p>
        </div>
{passwordShow ? 
<div className='fixed w-screen h-screen bg-punkpurple/80 z-40'>
        <div className='flex flex-col justify-center h-screen'>
          <div className='m-auto'>
          <input type='password' placeholder='Super secret password!' className='p-2 w-full m-2 rounded-xl text-center text-3xl' onChange={e => setPassword(e.target.value)}/>
          <button className='bg-punkpink w-full text-3xl m-2 p-2 rounded-xl hover:bg-black hover:text-punkpink' onClick={() => passwordCheck()}>Go!</button>
        </div>
        </div>
        </div>
        : null }

      <div className='m-auto text-center'>
        <div className='flex justify-center'>
        <img src={svlLogo} className='w-64 pt-16'/>
        </div>
  <h2 className={loading ? 'text-6xl text-white opacity-0 transition-opacity duration-500 delay-500 z-50' : 'text-6xl text-punkpink opacity-1 transition-opacity duration-500 delay-500 z-50'}>COMING SOON</h2>
  <h2 className={loading ? 'text-4xl text-white opacity-0 transition-opacity duration-500 delay-500 z-50' : 'text-6xl text-white opacity-1 transition-opacity duration-500 delay-500 z-50'}>Sam van Leer <span className='text-9xl'>x</span> THE PUNK PROGRAMMER</h2>
  <p className={loading ? 'text-3xl text-punkpink opacity-0 transition-opacity duration-500 delay-700 z-50' : 'text-2xl text-white opacity-1 transition-opacity duration-500 delay-700 z-50'}>Kickstart your freelance developer career</p>
  <p className={loading ? 'text-3xl text-punkpink opacity-0 transition-opacity duration-500 delay-700 z-50' : 'text-2xl text-white opacity-1 transition-opacity duration-500 delay-700 z-50'}>You can go from crawling to striding in:</p>
  <p className={loading ? 'text-3xl text-punkpink opacity-0 transition-opacity duration-500 delay-700 z-50' : 'text-base text-white opacity-1 transition-opacity duration-500 delay-700 z-50'}> - Web development</p>
  <p className={loading ? 'text-3xl text-punkpink opacity-0 transition-opacity duration-500 delay-700 z-50' : 'text-base text-white opacity-1 transition-opacity duration-500 delay-700 z-50'}> - Mobile App development</p>
  <p className={loading ? 'text-3xl text-punkpink opacity-0 transition-opacity duration-500 delay-700 z-50' : 'text-base text-white opacity-1 transition-opacity duration-500 delay-700 z-50'}> - Graphic Design</p>
  <p className={loading ? 'text-3xl text-punkpink opacity-0 transition-opacity duration-500 delay-700 z-50' : 'text-base text-white opacity-1 transition-opacity duration-500 delay-700 z-50'}> - Sales</p>


<div className={loading ? ' opacity-0 flex justify-center w-full transition-opacity duration-500 delay-300' : 'opacity-1 flex justify-center w-full transition-opacity duration-500 delay-300'}>
<img src={paint3} className='absolute w-2/3 top-40 -z-10'/>
</div>
      </div>
<div className='absolute -top-20 -right-20 flex justify-end -z-10'>
<img src={paint1} className={loading ? 'w-full opacity-0 transition-opacity duration-500 delay-1000' : 'w-full opacity-100 transition-opacity duration-500 delay-1000'}/>
</div>
    </div>
</>

:
<>
<NavBar />
<Footer />
<Routes>
<Route path="/" element={<Home />} />
<Route path="/about" element={<About />} />
<Route path="/modules" element={<Modules />} />
<Route path="/pricing" element={<Pricing />} />
<Route path="/community" element={<Community />} />
<Route path="/support" element={<Support />} />

<Route path="/course" element={<Course />} />
</Routes>

<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
</>

  )
}

export default App