import React, { useState } from 'react'
import ReactPlayer from 'react-player'

import placeholder from '../../assets/videos/placeholder.mp4'
import play from '../../assets/images/paintsplat/playSplat.svg'
import vidBg from '../../assets/images/vidBg.png'
import * as BsIcons from 'react-icons/bs'

function VideoPlayer() {

    const [playing, setPlaying] = useState(false)
const [muted, setMuted] = useState(false)
const [playSpeed, setPlaySpeed] = useState(1)


  return (
    <>
    <div className='player-wrapper absolute'>
    <div className=''>
        <ReactPlayer
        playing={playing}
        url={placeholder}
        muted={muted}
        // playIcon={<img src={play} className='w-44' onClick={() => setPlaying(true)}/>}
        // light={vidBg}
        playbackRate={playSpeed}
        className='react-player'
        width='100%'
        height='auto'
        config={{ 
            file: { 
                attributes: {
                    onContextMenu: e => e.preventDefault()
                } 
            } 
        }}
        />
        </div>

        
{!playing ?
        <div className='absolute top-0 w-full h-full flex justify-center bg-black/30 z-40'>
        <img src={play} className='w-44 hover:scale-125 duration-300' onClick={() => setPlaying(true)}/>
        </div>
        : null}

<div className='absolute bottom-0 w-full py-1 flex items-center text-black/30 flex justify-between items-center'>
       
<div className='flex items-center z-50'>

{!playing ? 
    <BsIcons.BsPlayFill size={30} onClick={() => setPlaying(true)} className='hover:text-punkpink hover:scale-110 duration-300 mr-4 ml-2'/>
    :
    <BsIcons.BsFillPauseFill size={30} onClick={() => setPlaying(false)} className='hover:text-punkpink hover:scale-110 duration-300 mr-4 ml-2'/>
}
<div className='border px-6 border-black/30 border-2 rounded-lg hover:border-punkpink hover:text-punkpink'>
    <h2>{playSpeed}</h2>
</div>

</div>

<div className='flex items-center z-50'>

{!muted ? 
    <BsIcons.BsFillVolumeUpFill size={30} onClick={() => setMuted(true)} className='hover:text-punkpink hover:scale-110 duration-300 mr-4 ml-2'/>
    :
    <BsIcons.BsFillVolumeMuteFill size={30} onClick={() => setMuted(false)} className='hover:text-punkpink hover:scale-110 duration-300 mr-4 ml-2'/>
}

</div>
    </div>
      </div>




    </>
  )
}

export default VideoPlayer