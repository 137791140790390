import React from 'react'

function Footer() {
  return (
    <div className='fixed bottom-0 w-screen text-center text-sm text-gray-400'>
<p>© {new Date().getFullYear()} | Sam van Leer</p>
    </div>

  )
}

export default Footer