import React from 'react'
import Waves from '../components/Waves'

function Community() {
    return (
        <>

    <div className='bg-gradient-to-t from-gray-600 to-black text-white min-h-screen pt-32'>

<div className='flex justify-center text-center'>
    <div>
<h2 className='text-5xl text-white pt-16'>Community</h2>
    <h2 className='text-3xl text-punkpink'>Hang out with us!</h2>
    </div>
        </div>
<div className='grid grid-cols-2 pt-4'>

<div className='text-center px-4'>
    <p className='pt-4'>Brief intro; I'm Sam. I made a modern course to show how to go from any (or no) career to owning and running your own small agency/ freelancer business.</p>
    
</div>

<div className='text-center px-4'>
    <p>Image here</p>
</div>

</div>
    </div>
    </>
  )
}

export default Community