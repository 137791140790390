import React from 'react'
import Waves from '../components/Waves'
import paint1 from '../assets/images/paintsplat/purpleWaves.svg'
import bigPink from '../assets/images/paintsplat/pinkBig.svg'
import HomeTitles from '../components/HomeTitles'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

function Home() {

    const length = HomeTitles.length
    const random = Math.floor(Math.random() * length)
    const title = HomeTitles[random]


    const passwordToast = () => {
        toast.error('Not built yet!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    }
  return (
    <>

    <div className='bg-gradient-to-t from-gray-600 to-black text-white min-h-screen pt-32 grid tablet:grid-cols-2 items-center'>

<div className='max-w-1/2 h-fit p-16 text-center' style={{backgroundImage:`url(${paint1})`, backgroundRepeat:'no-repeat', backgroundSize:'contain', backgroundPosition:'center'}}>
    <div className='flex justify-center'>
    <h3 className='text-4xl w-fit'>{title}</h3>
    </div>

    
    <div className='flex justify-center'>
    <Link to='/about'>
    <p className='hover:scale-110 duration-300 pt-2 w-fit'>What's this all about?</p>
    </Link>
    </div>
    
    <div className='flex justify-center'>
    <Link to='/modules'>
    <p className='hover:scale-110 duration-300 pt-2 w-fit'>What's covered in this course?</p>
    </Link>
    </div>
    

<div className='flex justify-center'>
<Link to='/support'>
    <p className='hover:scale-110 duration-300 pt-2 w-fit'>FAQ's</p>
</Link>
</div>
    
    <div className='flex justify-center'>
    <Link to='/'>
    <p className='hover:scale-110 duration-300 pt-2 w-fit'>Testimonials</p>
    </Link>
    </div>
 
</div>

<div className='flex justify-center p-24 mb-8 pt-16 text-black' style={{backgroundImage:`url(${bigPink})`, backgroundRepeat:'no-repeat', backgroundSize:'contain', backgroundPosition:'center'}} >
<div className='flex flex-col'>
    <h3 className='text-center text-white text-4xl'>Login</h3>
    <input placeholder='Email' className='px-1 rounded-xl my-2 py-2'/>
    <input type='password' placeholder='Password'className='px-1 rounded-xl my-2 py-2'/>
    <p className='text-center text-white text-sm' onClick={() => passwordToast()}>Forgotten password</p>
</div>
</div>


    </div>
    </>
  )
}

export default Home