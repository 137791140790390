import React from 'react'

const NavData = [
    {
        title: 'Home',
        path:'/'
    },
    {
        title: 'About',
        path:'/about'
    },
    {
        title: 'Modules',
        path:'/modules'
    },
    {
        title: 'Pricing',
        path:'/pricing'
    },
    {
        title: 'Community',
        path:'/community'
    },
    {
        title: 'FAQ\'s & Support',
        path:'/support'
    },
]

export default NavData