import React from 'react'
import Waves from '../components/Waves'

function About() {
    return (
        <>

    <div className='bg-gradient-to-t from-gray-600 to-black text-white min-h-screen pt-32'>

<div className='flex justify-center text-center'>
    <div>
<h2 className='text-5xl text-white pt-16'>The only course to kickstart your freelance career!</h2>
    <h2 className='text-3xl text-punkpink'>What the f*&% is this all about then?!?</h2>
    </div>
        </div>
<div className='grid grid-cols-2 pt-4'>

<div className='text-center px-4'>
    <p className='pt-4'>Brief intro; I'm Sam. I made a modern course to show how to go from any (or no) career to owning and running your own small agency/ freelancer business.</p>
    <p>By the end of the course, sure... you will have the skills to walk into a junior dev role or sales executive role.</p>
    <p>HOWEVER...this course is specifically for those who want to learn enough of each part of a business to start working for yourself.</p>
    <p className='pt-4'>The course is designed to give you a fast leg-up into the world of web development, app development but more importantly give you way to capitalise on your skills!</p> 
    <p>What's the point in being awesome at something if no-one knows it and you can't get paid for it.</p> 
    <p>My years of Sales experience has taught me lots of useful techniques, but this way you fast track it and cut through the bollocks.</p>
    <p>Oh also, I should mention, it's real talk only...expect swears. We're all friends in here!</p>
    <p className='pt-4'>I love clear and concise goals so...the end goal of this course is for you to be earning a living working for yourself. </p> 
</div>

<div className='text-center px-4'>
    <p>Image here</p>
</div>

</div>
    </div>
    </>
  )
}

export default About