import React from 'react'
import { Link } from 'react-router-dom'
import NavData from './NavData'
import svlLogo from '../../assets/images/svlLogo.svg'

function NavBar() {
  return (
    <div className='absolute top-0 w-screen text-white'>
        <div className='flex justify-between p-4 items-center'>
          <div>
            <Link to='/' className='text-center flex items-center hover:scale-110 duration-300'>
            <img src={svlLogo} className='h-28 object-contain'/>
            <div>
<h1 className='text-4xl'>SamVanLeer.co.uk</h1>
<p className='text-punkpink text-left'>The modern web dev course</p>
            </div>
            </Link>
          </div>
        <div className='flex'>
          {NavData.map(nav => (
            <div className='hover:scale-125 duration-300 hover:text-punkpink px-4'>
              <Link to={nav.path}>
              <p>{nav.title}</p>
              </Link>
              </div>
          ))}
        </div>
        </div>
    </div>
  )
}

export default NavBar