import React from 'react'

const HomeTitles = [
    'You can go your own waaaaaay!',
    'Punk developers...ASSEMBLE!',
    'For those who keep hearing: "Why are you so late?" from their boss.',
    'Write your resignation letter. Work for you.',
    'For those who can\'t get out of bed before 8:00 a.m.',
    'Bored of working on someone else\'s dream?',
    'Perfect for people who are bored of the same old rat race!'

]

export default HomeTitles